<template>
  <div
    :id="index"
    v-ripple
    class="fill-height d-flex flex-row align-center pa-2"
    :style="styleSettings.body"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
    @click="$emit('onSelect')"
  >
    <div
      class="d-flex flex-row align-center justify-space-between"
      style="width: 66%;"
    >
      <span
        v-if="!clicked || active === false"
        class="ml-2"
        style="user-select: none;"
        v-text="localItem.name"
      />
      <v-simple-checkbox
        v-if="localItem.showCheckbox"
        v-model="localItem.active"
      />
    </div>
    <div class="d-flex flex-column justify-start ml-auto fill-height">
      <v-icon
        v-show="hovered"
        class="mr-1"
        style="cursor: pointer;"
        @click="toggleClicked"
      >
        edit
      </v-icon>
      <v-dialog
        v-model="clicked"
        fullscreen
        transition="fade-transition"
        overlay-color="black"
        overlay-opacity="1"
      >
        <div>
          <div
            class="d-flex flex-row align-center pa-2"
            :style="styleSettings.editDialog"
          >
            <v-text-field
              v-if="clicked && active === true"
              :key="textFieldKey"
              v-model="localItem.name"
              v-on-clickaway="clickedAway"
              color="primary"
              clearable
              class="px-2"
              :autofocus="true"
              :placeholder="$t('competition.edit.insertName')"
              @keyup.enter="clicked = false"
            />
          </div>
          <v-btn
            class="mx-2"
            fab
            dark
            small
            color="primary"
            :style="styleSettings.editDialog.deleteButton"
            @click="$emit('onDelete', localItem)"
          >
            <v-icon dark>
              delete_outline
            </v-icon>
          </v-btn>
          <v-btn
            depressed
            color="primary"
            :style="styleSettings.editDialog.saveButton"
            @click="$emit('onUpdate', localItem)"
          >
            {{ $t('common.accept') }}
          </v-btn>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CompetitionCrudListItem',
  mixins: [clickaway],
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localItem: {
        name: '',
        showCheckbox: false,
      },
      textFieldKey: 0,
      active: false,
      hovered: false,
      clicked: false,
      currentElementPosition: {},
    }
  },
  computed: {
    styleSettings() {
      return {
        body: {
          backgroundColor: this.backgroundColorSelector(),
        },
        editDialog: {
          position: 'relative',
          width: `${this.currentElementPosition.width}px`,
          height: `${this.currentElementPosition.height}px`,
          top: `${this.currentElementPosition.top}px`,
          left: `${this.currentElementPosition.left}px`,
          background: 'white',
          border: '2px solid #dadada',
          borderRadius: '12px',
          deleteButton: {
            position: 'relative',
            height: `${this.currentElementPosition.height}px`,
            width: `${this.currentElementPosition.height}px`,
            top: `${this.currentElementPosition.top - this.currentElementPosition.height}px`,
            left: `${this.currentElementPosition.left + this.currentElementPosition.width + 16}px`,
          },
          saveButton: {
            position: 'relative',
            width: `${this.currentElementPosition.width / 2}px`,
            top: `${this.currentElementPosition.top + 16}px`,
            left: `${this.currentElementPosition.left - 60}px`,
          },
        },
      }
    },
  },
  created() {
    this.localItem = this.item
    if (this.localItem.name === '') {
      this.$nextTick(() => this.toggleClicked())
    }
  },
  methods: {
    backgroundColorSelector() {
      if (this.disabled) return this.$vuetify.theme.themes.light.inactive
      return null
    },
    clickedAway() {
      this.clicked = false
    },
    toggleClicked() {
      // eslint-disable-next-line no-unused-expressions
      this.clicked = true// : this.clicked = false
      this.active = true
      this.textFieldKey++
      this.currentElementPosition = document.getElementById(this.index).getBoundingClientRect()
    },
  },
}
</script>

<style>
.v-dialog--fullscreen {
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8) !important;
}
</style>
